import type { FacetCount, KeycloakAuth, NewOfferRequest, Offer, PageResult, TankNFTOnMarket, TankTokenV3 } from '$lib/types';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { getDefaultAxios, getDefaultAxiosWithToken } from './AxiosService';
import type { OrderSearchRequest } from '$lib/components/facetpanel/types';
import { ORDER_PAGE_SIZE, OrderStatus } from './OrderService';


export class TokenNFTService {
	keyCloack: KeycloakAuth | undefined;
	tokensURL = `/markets/tokens`;
	offersURL = `/markets/offers`;
	axios: AxiosInstance;

	constructor(keyCloack?: KeycloakAuth) {
		if (keyCloack) {
			this.keyCloack = keyCloack;
			this.axios = getDefaultAxiosWithToken(keyCloack);
		} else {
			this.axios = getDefaultAxios();
		}
	}

	async getTankNFTsOnMarket(orderSearchRequest: OrderSearchRequest, sort: string, pageNumber: number) {
		let url = this.tokensURL + '/onMarket/search';
		if (!!this.keyCloack) {
			url = this.tokensURL + '/onMarket/userlikes/search';
		}
		let resp = await this.axios.post<PageResult<TankNFTOnMarket>>(url, orderSearchRequest, {
			headers: {
				'Content-Type': 'application/json'
			},
			params: {
				sort,
				limit: ORDER_PAGE_SIZE,
				page: pageNumber
			}
		});
		return resp.data;
	}

	async GetFacetCountByOwner(address: string) {
		const resp = await this.axios.get<FacetCount[]>(`${this.tokensURL}/facet/network/count/owner/${address}`, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return resp.data;
	}

	async GetFacetCount() {
		const validFacets = ["race", "color", "class", "material",
			"elemenet", "generation", "foundertank", "network", "orderType"]
		const facets: Map<string, FacetCount[]> = new Map()
		for (const facet of validFacets) {
			const resp = await this.axios.get<FacetCount[]>(`${this.tokensURL}/facet/${facet}/count`, {
				headers: {
					'Content-Type': 'application/json'
				}
			});
			facets.set(facet, resp.data)
		}
		return facets;
	}

	async GetStakedByOwnerAndNetworkWithSort(
		owner: string,
		network: string,
		sort: string,
		sortDirection: string
	) {
		const params: any = {
			owner: owner,
			sort: sort,
			sortDirection: sortDirection,
			staked: true
		};
		if (network.length > 0) {
			params.network = network;
		}
		const resp = await this.axios.get<PageResult<TankTokenV3>>(this.tokensURL, {
			headers: {
				'Content-Type': 'application/json'
			},
			params: params
		});
		return resp.data.data;
	}

	async GetByOwnerAndNetworkWithSort(
		owner: string,
		network: string,
		sort: string,
		sortDirection: string
	) {
		const params: any = {
			owner: owner,
			sort: sort,
			sortDirection: sortDirection
		};
		if (network.length > 0) {
			params.network = network;
		}
		const resp = await this.axios.get<PageResult<TankTokenV3>>(this.tokensURL, {
			headers: {
				'Content-Type': 'application/json'
			},
			params: params
		});
		return resp.data.data;
	}

	async GetByNetworkContractAndTokenId(network: string, contract: string, tokenId: string) {
		let item = await this.axios.get<TankTokenV3>(`${this.tokensURL}/${network}/${contract}/${tokenId}`);
		return item.data;
	}

	async createOffer(bid: NewOfferRequest) {
		const resp = await this.axios.post<any, AxiosResponse<Offer>, any>(this.offersURL, bid, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return resp.data;
	}

	async getActiveOffersOfTankNFT(tankNFTId: string) {
		let resp = await this.axios.get<PageResult<Offer>>(`${this.offersURL}/tokens/${tankNFTId}`, {
			params: {
				"status": OrderStatus.Created
			}
		});
		return resp.data;
	}

	async getOffersToMe(address: string) {
		let resp = await this.axios.get<PageResult<Offer>>(`${this.offersURL}/selling/${address}`, {
			params: {
				status: OrderStatus.Created,
			}
		});
		return resp.data;
	}

	async getMySoldOffers(address: string) {
		let resp = await this.axios.get<PageResult<Offer>>(`${this.offersURL}/selling/${address}`, {
			params: {
				status: OrderStatus.Fullfilled,
			}
		});
		return resp.data;
	}

	async getOffersFromMe(address: string) {
		let resp = await this.axios.get<PageResult<Offer>>(`${this.offersURL}/buying/${address}`, {
			params: {
				status: OrderStatus.Created,
			}
		});
		return resp.data;
	}

	async getMyFullfilledOffers(address: string) {
		let resp = await this.axios.get<PageResult<Offer>>(`${this.offersURL}/buying/${address}`, {
			params: {
				status: OrderStatus.Fullfilled
			}
		});
		return resp.data;
	}

	async deleteOffers(offerId: string) {
		await this.axios.delete<PageResult<Offer>>(`${this.offersURL}/${offerId}`);
	}
}

export interface TankByOwnerResp {
	tokens: TankTokenV3[];
}
