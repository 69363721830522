import type { OrderSearchRequest } from '$lib/components/facetpanel/types';
import type {
	BidOrder,
	KeycloakAuth,
	MarketOrder,
	MarketOrderDetails,
	MarketOrderDetailsV2,
	NewBidOrderRequest,
	NewMarketOrderRequest,
	Offer,
	PageResult
} from '$lib/types';
import type { ConsiderationItem, OrderWithCounter } from '@opensea/seaport-js/lib/types';
import { getPresentItemAmount } from '@opensea/seaport-js/lib/utils/item';
import type { AxiosInstance, AxiosResponse } from 'axios';
import { ethers } from 'ethers';
import { NETWORK_POLYGON } from '../../constants';
import { getDefaultAxios, getDefaultAxiosWithToken } from './AxiosService';
import { numberWithCommas } from './WalletManager';
import { TokenNFTService } from './TokenNFTService';
import { BigNumber } from 'ethers';

export const ORDER_PAGE_SIZE = 20;
export const MAX_ORDER_PAGE_SIZE = 30000;

export const MarketOrderType = {
	Auction: 'auction',
	DutchAuction: 'dutch-auction',
	FixedPrice: 'fixed-price'
};

export const OrderStatus = {
	Created: 'created',
	Fullfilled: 'fullfilled',
	Cancelled: 'cancelled',
	Deleted: 'deleted',
	Rejected: 'rejected'
};

export function getOrderType(order: MarketOrder | Offer | undefined) {
	if (order) {
		if (isOffer(order)) {
			return 'Offer'
		}
		switch (order.type) {
			case MarketOrderType.Auction:
				return 'English Auction';
			case MarketOrderType.DutchAuction:
				return 'Dutch Auction';
			default:
				return '';
		}
	}
	return '';
}

export function isBidOrder(item: OrderWithCounter): item is BidOrder {
	return !!item && !!(item as BidOrder).orderId;
}

export function isOffer(item: OrderWithCounter): item is Offer {
	return !!item && !!(item as Offer).tankNFTId;
}

export function initOrderSearchRequest() {
	let orderSearchRequest: OrderSearchRequest = {
		clazz: [],
		color: [],
		elemenet: [],
		network: [],
		material: [],
		foundertank: [],
		race: [],
		type: [],
		status: [OrderStatus.Created],
		price_gt: "",
		price_lt: ""
	};
	return orderSearchRequest;
}

export function salePriceETH(consideration: ConsiderationItem[]) {
	if (consideration.length > 1) {
		return ethers.utils.formatEther(
			BigNumber.from(consideration[0].startAmount).
				add(BigNumber.from(consideration[1].startAmount))
		);
	}
	return ethers.utils.formatEther(consideration[0].startAmount);
}

export const getCurrentPrice = async (item: MarketOrderDetailsV2 | Offer) => {
	if (isOffer(item)) {
		let service = new TokenNFTService();
		const offers = await service.getActiveOffersOfTankNFT(item.tankNFTId);
		if (offers.data.length > 0) {
			const currentPrice = ethers.utils.formatEther(offers.data[0].parameters.offer[0].startAmount);
			console.log('currentPrice: ', currentPrice);
			return currentPrice;
		} else {
			const currentPrice = ethers.utils.formatEther(item.parameters.consideration[0].startAmount);
			return currentPrice;
		}
	}
	if (isEnglishAction(item)) {
		let orderservice = new OrderService();
		const bids = await orderservice.getBidOrders(item.id);
		if (bids.data.length > 0) {
			const currentPrice = ethers.utils.formatEther(bids.data[0].parameters.offer[0].startAmount);
			console.log('currentPrice: ', currentPrice);
			return currentPrice;
		} else {
			const currentPrice = ethers.utils.formatEther(item.parameters.consideration[0].startAmount);
			return currentPrice;
		}
	}
	if (isDutchAction(item)) {
		const bid = await getCurrentBidPrice(item);
		const currentPrice = ethers.utils.formatEther(bid);
		return currentPrice;
	}
	if (isSaleOrder(item)) {
		return salePriceETH(item.parameters.consideration)
	}
	return '';
};

export function getCurrentPriceLabel(item: MarketOrder | Offer, currentPrice?: string) {
	if (isOffer(item)) {
		return 'Top offer';
	}
	if (isEnglishAction(item)) {
		return 'Top bid';
	}
	if (isDutchAction(item)) {
		if (!!currentPrice) {
			return 'Current price';
		} else {
			return 'Price';
		}
	}
	if (isSaleOrder(item)) {
		return 'Fixed price';
	}
	return 'price';
}

export function getCurrencyIcon(order: MarketOrderDetailsV2 | Offer | undefined) {
	if (typeof order === 'undefined') {
		return '';
	}
	if (isOffer(order)) {
		if (order.tankNFT.network === NETWORK_POLYGON) {
			return 'icon-polygon-wmatic';
		}
		return 'icon-mainnet-weth';
	}
	if (order.metadata.network === NETWORK_POLYGON) {
		if (isEnglishAction(order)) {
			return 'icon-polygon-wmatic';
		} else {
			return 'icon-polygon-matic';
		}
	} else {
		if (isEnglishAction(order)) {
			return 'icon-mainnet-weth';
		} else {
			return 'icon-mainnet-eth';
		}
	}
}

export function convertToUSD(
	network: string | undefined,
	currentPriceText: string,
	rate: {
		mainnet: number;
		polygon: number;
	}
) {
	if (typeof network === 'undefined') {
		return '';
	}
	if (network === NETWORK_POLYGON) {
		return Math.round(parseFloat(currentPriceText) * rate.polygon * 1e4) / 1e4;
	} else {
		return Math.round(parseFloat(currentPriceText) * rate.mainnet * 1e4) / 1e4;
	}
}

export function getCurrencyTypeText(order: MarketOrderDetailsV2 | Offer | undefined) {
	if (typeof order === 'undefined') {
		return '';
	}
	if (isOffer(order)) {
		if (order.tankNFT.network === NETWORK_POLYGON) {
			return 'WMATIC';
		}
		return 'WETH';
	}
	if (order.metadata.network === NETWORK_POLYGON) {
		if (isEnglishAction(order)) {
			return 'WMATIC';
		} else {
			return 'MATIC';
		}
	} else {
		if (isEnglishAction(order)) {
			return 'WETH';
		} else {
			return 'ETH';
		}
	}
}

export function isEnglishAction(item: MarketOrder | undefined) {
	return item && item.type === MarketOrderType.Auction;
}

export function isSaleOrder(item: MarketOrder | undefined) {
	return item && item.type === MarketOrderType.FixedPrice;
}

export function isDutchAction(item: MarketOrder | undefined) {
	return item && item.type === MarketOrderType.DutchAuction;
}

export async function getCurrentBidPrice(item: MarketOrder) {
	const library = new ethers.providers.Web3Provider(window?.ethereum);
	const currentNumber = await library.getBlockNumber();
	const currentBlock = await library.getBlock(currentNumber);
	const currentBlockTimestamp = currentBlock.timestamp;

	let considerationItem = item.parameters.consideration[0];
	if (item.parameters.consideration.length > 1) {
		let considerationItem1 = item.parameters.consideration[1];
		const amount = getPresentItemAmount({
			endAmount: `${BigNumber.from(considerationItem.endAmount).
				add(BigNumber.from(considerationItem1.endAmount))}`,
			startAmount: `${BigNumber.from(considerationItem.startAmount).
				add(BigNumber.from(considerationItem1.startAmount))}`,
			timeBasedItemParams: {
				endTime: item.parameters.endTime,
				isConsiderationItem: false,
				startTime: item.parameters.startTime,
				currentBlockTimestamp: currentBlockTimestamp,
				ascendingAmountTimestampBuffer: 10
			}
		});
		return amount;
	}

	const amount = getPresentItemAmount({
		endAmount: considerationItem.endAmount,
		startAmount: considerationItem.startAmount,
		timeBasedItemParams: {
			endTime: item.parameters.endTime,
			isConsiderationItem: false,
			startTime: item.parameters.startTime,
			currentBlockTimestamp: currentBlockTimestamp,
			ascendingAmountTimestampBuffer: 10
		}
	});
	return amount;
}

export function imageHTTPURL(data: MarketOrderDetails[]): MarketOrderDetails[] {
	return data.map((r) => {
		let tokenImageURL = r.metadata.imgUrl.replace('https://', 'http://');
		return {
			...r,
			tokenImageURL
		};
	});
}

export class OrderService {
	keyCloack: KeycloakAuth | undefined;
	ordersURL = `${import.meta.env.VITE_OAUTH_REDIRECT_HOST}/markets/orders`;
	bidsURL = `/markets/bids`;
	axios: AxiosInstance;

	constructor(keyCloack?: KeycloakAuth) {
		if (keyCloack) {
			this.keyCloack = keyCloack;
			this.axios = getDefaultAxiosWithToken(keyCloack);
		} else {
			this.axios = getDefaultAxios();
		}
	}

	async getOrders(orderSearchRequest: OrderSearchRequest, sort: string, pageNumber: number) {
		let url = this.ordersURL + '/search';
		if (!!this.keyCloack) {
			url = this.ordersURL + '/userlikes/search';
		}
		let resp = await this.axios.post<PageResult<MarketOrderDetails>>(url, orderSearchRequest, {
			headers: {
				'Content-Type': 'application/json'
			},
			params: {
				sort,
				limit: ORDER_PAGE_SIZE,
				page: pageNumber
			}
		});
		return resp.data;
	}

	async GetWishList() {
		let item = await this.axios.get<PageResult<MarketOrderDetails>>(this.ordersURL + '/myWishList');
		return item.data.data;
	}

	async getClosedOrders(pageNumber: number) {
		let body: any = { status: [OrderStatus.Fullfilled] };

		let resp = await this.axios.post<PageResult<MarketOrderDetails>>(
			this.ordersURL + '/search',
			body,
			{
				headers: {
					'Content-Type': 'application/json'
				},
				params: {
					sort: '-created_at',
					limit: ORDER_PAGE_SIZE,
					page: pageNumber
				}
			}
		);
		return resp.data;
	}

	async findMarketOrderByOwner(owner: string) {
		let body: any = {
			status: [OrderStatus.Created],
			seller: owner
		};
		const resp = await this.axios.post<any, AxiosResponse<PageResult<MarketOrderDetails>>, any>(
			this.ordersURL + '/search',
			body,
			{
				headers: {
					'Content-Type': 'application/json'
				},
				params: {
					limit: MAX_ORDER_PAGE_SIZE,
					page: 1
				}
			}
		);
		return resp.data.data;
	}

	async findExecutedMarketOrderBySeller(seller: string) {
		let body: any = {
			status: [OrderStatus.Deleted, OrderStatus.Fullfilled],
			seller: seller
		};
		const resp = await this.axios.post<any, AxiosResponse<PageResult<MarketOrderDetails>>, any>(
			this.ordersURL + '/search',
			body,
			{
				headers: {
					'Content-Type': 'application/json'
				},
				params: {
					limit: MAX_ORDER_PAGE_SIZE,
					page: 1,
					sort: '-created_at'
				}
			}
		);
		return resp.data.data;
	}

	async findExecutedMarketOrderByBuyer(buyer: string) {
		let body: any = {
			status: [OrderStatus.Deleted, OrderStatus.Fullfilled],
			buyer: buyer
		};
		const resp = await this.axios.post<any, AxiosResponse<PageResult<MarketOrderDetails>>, any>(
			this.ordersURL + '/search',
			body,
			{
				headers: {
					'Content-Type': 'application/json'
				},
				params: {
					limit: MAX_ORDER_PAGE_SIZE,
					page: 1,
					sort: '-created_at'
				}
			}
		);
		return resp.data.data;
	}

	async getOrder(id: string) {
		let url = `/markets/orders/${id}`;
		if (!!this.keyCloack) {
			url = url + '/withLikes';
		}
		let resp = await this.axios.get<MarketOrderDetails>(url);
		return resp.data;
	}

	async createOrder(order: NewMarketOrderRequest) {
		const resp = await this.axios.post<any, AxiosResponse<MarketOrderDetails>, any>(
			this.ordersURL,
			order,
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		return resp.data;
	}

	async createBidOrder(bid: NewBidOrderRequest) {
		const resp = await this.axios.post<any, AxiosResponse<BidOrder>, any>(this.bidsURL, bid, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
		return resp.data;
	}

	async getBidOrders(orderId: string) {
		let resp = await this.axios.get<PageResult<BidOrder>>(`${this.ordersURL}\\${orderId}\\bids`);
		return resp.data;
	}

	async getMyActiveBidOrders(address: string) {
		let resp = await this.axios.get<PageResult<BidOrder>>(`${this.bidsURL}`, {
			params: {
				address: address,
				status: OrderStatus.Created,
				removeDuplicates: true
			}
		});
		return resp.data;
	}

	async getMyPastBidOrders(address: string) {
		let resp = await this.axios.get<PageResult<BidOrder>>(`${this.bidsURL}`, {
			params: {
				address: address,
				status: `${[OrderStatus.Rejected, OrderStatus.Cancelled, OrderStatus.Fullfilled].join(',')}`
			}
		});
		return resp.data;
	}

	async deleteOrder(order: MarketOrderDetails) {
		let resp = await this.axios.delete<MarketOrder>(`/markets/orders/${order.id}`);
		return resp.data;
	}
}
